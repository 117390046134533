<template>
  <div class="w-full min-h-screen mx-auto relative flex flex-col">
    <div class="bg-gray-50 p-4 flex flex-row justify-between items-center">
      <img
        src="../../assets/galecta-logo-black-nobg.svg"
        alt=""
        class="h-6 md:h-9"
      />
      <div
        class="flex flex-row gap-3 items-center border border-[#01215c] rounded-full px-2 py-1 shadow text-xs md:text-base"
      >
        <font-awesome-icon
          icon="fa-solid fa-user-circle"
          class="text-base md:text-xl"
        />
        User name
        <font-awesome-icon icon="fa-solid fa-caret-down" class="text-sm" />
      </div>
    </div>
    <div
      class="w-full 2xl:max-w-7xl mx-auto min-h-[95vh]flex flex-col items-center 2xl:py-16"
    >
      <Tracking completedStage="5" currentPage="6" />
      <form @submit.prevent="documentSubmit">
        <div
          class="flex flex-row justify-between items-center text-xl w-full px-2 font-bold"
        >
          <router-link
            :to="{ name: 'EducationInfoForm' }"
            class="font-medium text-base"
          >
            <font-awesome-icon
              icon="fas fa-chevron-left"
              class="pr-3"
            />Academic Information
          </router-link>
          <p class="text-start text-xl font-medium text-teal-600">
            Document Upload
          </p>
          <!-- <router-link :to="{name: 'DocumentUploadForm'}" class="font-medium text-base"> -->
          <p class="font-medium text-base">
            Document Preview
            <font-awesome-icon icon="fas fa-chevron-right" class="pr-3" />
          </p>
          <!-- </router-link> -->
        </div>
        <div
          class="w-full flex flex-col items-center min-h-full bg-slate-50 rounded-xl"
        >
          <div class="hidden">
            <input
              type="text"
              name="first_name"
              id="fullName"
              v-model="this.formStudentInfo.fullName"
            />
            <input
              type="email"
              name="email"
              id="emailAddress"
              v-model="this.formStudentInfo.emailAddress"
            />
            <input
              type="tel"
              id="countryCodePrimary"
              name="countryCodePrimary"
              v-model="this.formStudentInfo.CodePrimary"
            />
            <input
              type="tel"
              name="mobile_numberPrimary"
              id="mobile_numberPrimary"
              v-model="this.formStudentInfo.mobilePrimary"
            />
          </div>

          <div
            class="w-full flex flex-col-reverse xl:flex-row rounded-xl overflow-hidden gap-2 md:gap-8 p-4 md:p-8 h-[85vh] xl:h-[65vh]"
          >
            <div
              v-show="this.isStepOne"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full h-full flex flex-col gap-5 md:gap-10 items-start md:items-center md:justify-center md:mb-24 mt-6"
              >
                <p class="text-start text-xl md:text-3xl font-light">Photo</p>
                <div class="w-full md:w-2/3 text-start">
                  <label for="passPortPhoto" class="w-full"
                    >Upload Your Recent Passport Size Photograph</label
                  >
                  <small
                    v-if="this.fileUpload.passport.uploaded == true"
                    @click="this.is_value = this.fileUpload.passport.file"
                    class="border border-sky-200 hover:bg-sky-100 px-3 py-1.5 rounded cursor-pointer text-sky-600 ml-2"
                    >Preview</small
                  >
                  <input
                    @change="uploadImage('passport', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="passPortPhoto"
                    name="passport_photo"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl text-xs md:text-sm"
                >
                  Only images with .jpg, .jpeg, .png formats.
                  <b>No selfie</b> allowed. Size less than <b> 5MB</b>
                </div>
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-5xl md:text-7xl text-gray-300"
                    @click="selectStep(1)"
                  />
                  <div v-if="this.fileUpload.passport.file">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-5xl md:text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(2)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepTwo"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Date of Birth Proof
                </p>
                <div class="w-full md:w-2/3 text-start">
                  <label for="dobProof" class="w-full text-sm"></label
                  ><small
                    v-if="this.fileUpload.dob.uploaded == true"
                    @click="
                      (this.is_popUp = true),
                        (this.is_value = this.fileUpload.dob.file)
                    "
                    class="border p-0.5 rounded cursor-pointer text-blue-600"
                    >Preview</small
                  >
                  <input
                    @change="uploadImage('dob', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="dobProof"
                    name="dob_proof"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  <p>
                    10th Mark Sheet / Aadhar Card / Passport / National ID /
                    Driving Licence
                  </p>
                  <p>Only PDF is allowed. Size should be less than 10MB.</p>
                </div>
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(1)"
                  />
                  <div v-if="this.fileUpload.dob.file">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(3)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepThree"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  10th / Equivalent Grade Mark Sheet
                </p>
                <div class="w-full md:w-2/3 text-start">
                  <input
                    @change="uploadImage('secoundarymark', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="secondaryMarkSheet"
                    name="secondary_mark_sheet"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  <p>Only PDF is allowed. Size should be less than 10MB.</p>
                </div>
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(2)"
                  />
                  <div v-if="this.fileUpload.secoundarymark.file">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(4)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFour"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  12th / Equivalent Grade Mark Sheet
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <input
                    @change="uploadImage('puemark', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="pueMarkSheet"
                    name="pue_mark_sheet"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  <p>Only PDF is allowed. Size should be less than 10MB.</p>
                </div>
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(3)"
                  />
                  <div v-if="this.fileUpload.puemark.file">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(5)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFive"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Consolitated semester / year Mark sheets
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <input
                    @change="uploadImage('consolitated', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="ugCertificate"
                    name="ug_certificate"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  <p>Only PDF is allowed. Size should be less than 10MB.</p>
                </div>
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                  <div v-if="this.fileUpload.consolitated.file">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(6)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepSix"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Degree Certificate / Convocation Certificate
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <input
                    @change="uploadImage('degree', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="ugCertificate"
                    name="ug_certificate"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  <p>Only PDF is allowed. Size should be less than 10MB.</p>
                </div>
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(5)"
                  />
                  <!-- <button type="submit" class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]">Save & Continue</button> -->
                  <div v-if="this.fileUpload.degree.file">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(7)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepSeven"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Upload KYC Document
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <input
                    @change="uploadImage('kyc', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="kycDocument"
                    name="kyc_document"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  <p>Only PDF is allowed. Size should be less than 10MB.</p>
                </div>
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(6)"
                  />
                  <div v-if="this.fileUpload.kyc.file">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(8)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="this.isStepEight"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Community Certificate
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <input
                    @change="uploadImage('community', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="communityCertificate"
                    name="community_certificate"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  <p>Only PDF is allowed. Size should be less than 10MB.</p>
                </div>
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(7)"
                  />
                  <div v-if="this.fileUpload.community.file">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(9)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="this.isStepNine"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Other Supporting Document
                </p>
                <div class="w-full md:w-1/2 text-start">
                  <input
                    @change="uploadImage('supportDoc', $event)"
                    class="mt-2 mb-1 relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-sky-100 bg-white bg-clip-padding px-3 pt-1.5 pb-1 font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-sky-200 file:px-3 file:py-1.5 file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-sky-400 focus:text-neutral-700 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    type="file"
                    id="otherDocument"
                    name="other_document"
                  />
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-col justify-center items-center p-3 text-black font-light rounded-xl text-sm"
                >
                  <p>Only PDF is allowed. Size should be less than 10MB.</p>
                </div>
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(8)"
                  />
                  <div v-if="this.fileUpload.supportDoc.file">
                    <button
                      type="submit"
                      class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                    >
                      Save & Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="w-full xl:w-2/5 h-auto bg-white flex flex-row items-center justify-center rounded-xl md:shadow-xl shadow-slate-300/50 p-3 text-sm"
            >
              <div class="flex flex-col gap-3">
                <!-- <div class="flex flex-row gap-3 items-center">
               <p class="text-[#01215c] font-medium"><font-awesome-icon icon="fa-solid fa-user-circle" class="text-xl pr-2"/> Name</p>
               <p class="font-light text-[#01215c]">{{ this.formStudentInfo.fullName }}</p>
             </div>
             <div class="flex flex-row gap-3 items-center">
               <p class="text-[#01215c] font-medium"><font-awesome-icon icon="fa-solid fa-at" class="text-xl pr-2"/>Email</p>
               <p class="font-light text-[#01215c]">{{ this.formStudentInfo.emailAddress }}</p>
             </div>
             <div class="flex flex-row gap-3 items-center">
               <p class="text-[#01215c] font-medium"><font-awesome-icon icon="fa-solid fa-mobile" class="text-xl pr-2"/> Phone</p>
               <p class="font-light text-[#01215c]">{{ this.formStudentInfo.CodePrimary }} {{ this.formStudentInfo.mobilePrimary }}</p>
             </div>
             <div v-if="this.formStudentInfo.selectedProgram" class="flex flex-row gap-3 items-center">
               <p class="text-[#01215c] font-medium">
                 <font-awesome-icon icon="fa-solid fa-book" class="text-xl pr-2"/> Program</p>
               <p class="font-light text-[#01215c]"> {{ this.formStudentInfo.selectedProgram }}</p>
             </div>
             <div v-if="this.formStudentInfo.selectedElective" class="flex flex-row gap-3 items-center">
               <p class="text-[#01215c] font-medium">
                 <font-awesome-icon icon="fa-solid fa-bookmark" class="text-xl pr-2"/> Elective</p>
               <p class="font-light text-[#01215c]"> {{ this.formStudentInfo.selectedElective }}</p>
             </div>
             <div v-if="this.formStudentInfo.selectedNationality" class="flex flex-row gap-3 items-center">
               <p class="text-[#01215c] font-medium">
                 <font-awesome-icon icon="fa-solid fa-flag" class="text-xl pr-2"/> Nationality</p>
               <p class="font-light text-[#01215c]"> {{ this.formStudentInfo.selectedNationality }}</p>
             </div> -->
                <div class="w-full overflow-auto">
                  <img :src="this.is_value" alt="" class="h-[420px]" />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex flex-row px-8 bg-pink-200">
         <div class="w-full 2xl:w-3/5 flex flex-row justify-between px-2">
           <font-awesome-icon icon="fa-solid fa-caret-left" class="text-7xl text-[#01215c]" />
           <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c]" />
         </div>
       </div> -->
        </div>
      </form>
      <!-- <img src="../../assets/background.png" alt="background" class="absolute inset-0"> -->
    </div>
  </div>
</template>

<script>
// import Navigation from '@/components/NavigationView.vue'
import Tracking from "@/components/TrackingView.vue";
// import LeftNavigation from '@/components/FormLeftNavView.vue'
import axios from "axios";
export default {
  name: "DocumentInfoForm",
  data() {
    return {
      is_popUp: false,
      is_value: "",
      is_passPortPhoto: false,
      is_dobProof: false,
      is_secondaryMarkSheet: false,
      is_pueMarkSheet: false,
      is_ugMarkSheet: false,
      is_ugCertificate: false,
      is_communityCertificate: false,
      is_otherDocument: false,
      is_kycDocument: false,
      is_already_done: false,
      fileUpload: {
        passport: {
          file: "",
          uploaded: false,
        },
        dob: {
          file: "",
          uploaded: false,
        },
        secoundarymark: {
          file: "",
          uploaded: false,
        },
        puemark: {
          file: "",
          uploaded: false,
        },
        consolitated: {
          file: "",
          uploaded: false,
        },
        degree: {
          file: "",
          uploaded: false,
        },
        kyc: {
          file: "",
          uploaded: false,
        },
        community: {
          file: "",
          uploaded: false,
        },
        supportDoc: {
          file: "",
          uploaded: false,
        },
      },

      isStepOne: true,
      isStepTwo: false,
      isStepThree: false,
      isStepFour: false,
      isStepFive: false,
      isStepSix: false,
      isStepSeven: false,
      isStepEight: false,
      isStepNine: false,

      formStudentInfo: {
        fullName: "Sample Name",
        emailAddress: "johndoe@gmail.com",
        CodePrimary: "+91",
        mobilePrimary: "9898767676",
        selectedProgram: "",
        selectedElective: "",
        selectedNationality: "",
        selectedGender: "",
        selectedKycName: "",
        selectedKycDob: "",
        selectedGaurdianName: "",
        selectedAlterNumber: "",
        selectedHighestEducation: "",
        selectedHighestEducationLocation: "",
        selectedDiabled: "",
        selectedMiltary: "",
      },
    };
  },
  components: {
    Tracking,
  },

  created() {
    let Tracking = JSON.parse(localStorage.getItem("Tracking"));
    if (Tracking["document"] == true) {
      this.is_already_done = true;
    }
  },

  methods: {
    uploadImage(name, el) {
      console.log(name, el.target.files[0]);
      let bodyVal = new FormData();
      bodyVal.append("file_uploaded", el.target.files[0]);
      console.log(el.target.files[0]);
      axios
        .post(
          "https://api.maggana.com/mdfile_image_upload/?Key=d99a745ef58df61081fc91eed359a14e1d24e499",
          bodyVal,
          {
            headers: {
              "Access-Key": null,
            },
          }
        )
        .then((resp) => {
          if (name == "passport") {
            this.fileUpload["passport"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.passport.file;
            this.fileUpload["passport"]["uploaded"] = true;
          } else if (name == "dob") {
            this.fileUpload["dob"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.dob.file;
            this.fileUpload["dob"]["uploaded"] = true;
          } else if (name == "secoundarymark") {
            this.fileUpload["secoundarymark"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.secoundarymark.file;
            this.fileUpload["secoundarymark"]["uploaded"] = true;
          } else if (name == "puemark") {
            this.fileUpload["puemark"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.puemark.file;
            this.fileUpload["puemark"]["uploaded"] = true;
          } else if (name == "consolitated") {
            this.fileUpload["consolitated"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.consolitated.file;
            this.fileUpload["consolitated"]["uploaded"] = true;
          } else if (name == "degree") {
            this.fileUpload["degree"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.degree.file;
            this.fileUpload["degree"]["uploaded"] = true;
          } else if (name == "kyc") {
            this.fileUpload["kyc"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.kyc.file;
            this.fileUpload["kyc"]["uploaded"] = true;
          } else if (name == "community") {
            this.fileUpload["community"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.community.file;
            this.fileUpload["community"]["uploaded"] = true;
          } else if (name == "supportDoc") {
            this.fileUpload["supportDoc"]["file"] = resp.data.file_url;
            this.is_value = this.fileUpload.supportDoc.file;
            this.fileUpload["supportDoc"]["uploaded"] = true;
          }
        });
    },

    documentSubmit() {
      console.log(this.fileUpload);
      let getStudentVal = JSON.parse(localStorage.getItem("studentInfo"));
      let Tracking = JSON.parse(localStorage.getItem("Tracking"));
      let documentstatus = {
        document_formdata: this.fileUpload,
        submitTime: new Date(),
      };
      getStudentVal["formsInfo"]["Documents"] = documentstatus;
      localStorage.setItem("studentInfo", JSON.stringify(getStudentVal));
      Tracking["document"] = true;

      localStorage.setItem("Tracking", JSON.stringify(Tracking));
    },
    selectStep(step) {
      if (step == 1) {
        this.isStepOne = true;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.is_value = this.fileUpload.passport.file;
      } else if (step == 2) {
        this.isStepOne = false;
        this.isStepTwo = true;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.is_value = this.fileUpload.dob.file;
      } else if (step == 3) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = true;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.is_value = this.fileUpload.secoundarymark.file;
      } else if (step == 4) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = true;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.is_value = this.fileUpload.puemark.file;
      } else if (step == 5) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = true;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.is_value = this.fileUpload.consolitated.file;
      } else if (step == 6) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = true;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.is_value = this.fileUpload.degree.file;
      } else if (step == 7) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = true;
        this.isStepEight = false;
        this.isStepNine = false;
        this.is_value = this.fileUpload.kyc.file;
      } else if (step == 8) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = true;
        this.isStepNine = false;
        this.is_value = this.fileUpload.community.file;
      } else if (step == 9) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = true;
        this.is_value = this.fileUpload.supportDoc.file;
      }
    },
    // @click="selectFile('passport')"
  },
};
</script>

<style src="@/assets/css/ProgressTracking.css"></style>
