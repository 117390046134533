<template>
  <div class="w-full min-h-screen mx-auto relative flex flex-col">
    <div class="bg-gray-50 p-4 flex flex-row justify-between items-center">
      <img
        src="../../assets/galecta-logo-black-nobg.svg"
        alt=""
        class="h-6 md:h-9"
      />
      <div
        class="flex flex-row gap-3 items-center border border-[#01215c] rounded-full px-2 py-1 shadow text-xs md:text-base"
      >
        <font-awesome-icon
          icon="fa-solid fa-user-circle"
          class="text-base md:text-xl"
        />
        User name
        <font-awesome-icon icon="fa-solid fa-caret-down" class="text-sm" />
      </div>
    </div>
    <div
      class="w-full 2xl:max-w-7xl mx-auto min-h-[95vh]flex flex-col items-center 2xl:py-16"
    >
      <Tracking completedStage="1" currentPage="2" />
      <form @submit.prevent="feeinfo">
        <div
          class="flex flex-row justify-between items-center text-xl w-full px-2 font-bold"
        >
          <router-link
            :to="{ name: 'BasicInfoForm' }"
            class="font-medium text-base"
          >
            <font-awesome-icon
              icon="fas fa-chevron-left"
              class="pr-3"
            />Personal Information
          </router-link>
          <p class="text-start text-xl font-medium text-teal-600">
            Fee Payment
          </p>
          <router-link
            :to="{ name: 'KycInfoForm' }"
            class="font-medium text-base"
          >
            KYC Data
            <font-awesome-icon icon="fas fa-chevron-right" class="pr-3" />
          </router-link>
        </div>
        <div
          class="w-full flex flex-col items-center min-h-full bg-slate-50 rounded-xl relative"
        >
          <div class="hidden">
            <input
              type="text"
              name="first_name"
              id="fullName"
              v-model="this.formStudentInfo.fullName"
            />
            <input
              type="email"
              name="email"
              id="emailAddress"
              v-model="this.formStudentInfo.emailAddress"
            />
            <input
              type="tel"
              id="countryCodePrimary"
              name="countryCodePrimary"
              v-model="this.formStudentInfo.CodePrimary"
            />
            <input
              type="tel"
              name="mobile_numberPrimary"
              id="mobile_numberPrimary"
              v-model="this.formStudentInfo.mobilePrimary"
            />
            <input
              type="tel"
              name="selected_program"
              id="selectedProgram"
              v-model="this.formStudentInfo.selectedProgram"
            />
            <input
              type="tel"
              name="selected_elective"
              id="selectedElective"
              v-model="this.formStudentInfo.selectElective"
            />
          </div>
          <!-- <div class="">
         <div class="text-start text-2xl font-light">
           <p class="text-start text-2xl font-light">
             Select Your Program
           </p>
         </div>
       </div> -->
          <div
            class="w-full flex flex-col-reverse xl:flex-row rounded-xl overflow-hidden gap-2 md:gap-8 p-4 md:p-8 h-[85vh] xl:h-[65vh]"
          >
            <div
              v-show="this.isStepOne"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full h-full flex flex-col gap-5 md:gap-10 items-start md:items-center md:justify-center md:mb-24 mt-6"
              >
                <p class="text-start text-xl md:text-3xl font-light">
                  Fee Preference
                </p>
                <div class="w-full md:w-1/2 text-start flex flex-col gap-2">
                  <label
                    for="feePreference"
                    class="whitespace-nowrap pr-3 text-base font-medium"
                    >Select fee preference</label
                  >
                  <select
                    @change="findFinalFee"
                    id="feePreference"
                    name="fee_preference"
                    autocomplete="feePreference"
                    v-model="this.formFeeInfo.feePreference"
                    class="block w-full border border-sky-100 bg-white py-2 px-3 focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected value="Program fee">
                      Full program fee
                    </option>
                    <option value="Yearly fee">Yearly fee</option>
                    <option value="Semester fee">Semester fee</option>
                  </select>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl text-xs md:text-sm">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-5xl md:text-7xl text-gray-300"
                    @click="selectStep(1)"
                  />
                  <div v-if="this.formFeeInfo.feePreference">
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-5xl md:text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(2)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepTwo"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-3xl font-light">
                  Redeem scholarship (STEP 2)
                </p>
                <!-- <small>Use the coupon sent to you via email by counselor</small> -->
                <div class="w-full md:w-1/2 text-start">
                  <div class="w-full flex flex-col p-2 relative">
                    <div class="text-xs px-3 text-start flex flex-col py-1">
                      <div class="flex justify-between items-center">
                        <!-- <span class="text-sm">Redeem scholarship </span> -->
                        <!-- <button v-if="this.discount == 0" @click="this.is_coupon_open=true" class="flex justify-center items-center text-white font-bold rounded-full bg-sky-600 hover:bg-[#01215c] w-6 h-6"> % </button> -->
                      </div>
                    </div>
                    <div class="text-start flex flex-col gap-3 font-medium">
                      <p>Enter coupon code</p>
                      <input
                        v-model="this.formFeeInfo.coupon_code"
                        type="text"
                        id="couponCode"
                        name="coupon_code"
                        class="px-3 h-8 rounded-md text-gray-700"
                        required
                      />
                      <!-- <div>
                        <button @click="this.is_coupon_open=false" class="border border-gray-200 px-3 py-1.5 rounded-md">Apply</button>
                      </div> -->
                    </div>
                    <!-- <div v-if="this.discount > 0" class=" px-3 text-start">
                      Discount coupon: <b>{{ coupon_code }}</b>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <div
                  class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl"
                >
                  Use the coupon sent to you via email by counselor
                </div>
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(1)"
                  />
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(3)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepThree"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div class="w-full flex flex-col gap-5 items-center mb-24">
                <p class="text-start text-3xl font-light">Fee Breakup</p>
                <div class="w-full xl:w-2/3 text-start">
                  <div class="flex flex-col gap-2 px-4">
                    <div
                      class="w-full flex flex-row text-start font-semibold py-2 border-b border-sky-100"
                    >
                      <p class="w-1/2 text-start">Fee Heads</p>
                      <p class="w-1/2 text-end">Amount</p>
                    </div>
                    <div class="w-full flex flex-row">
                      <p class="w-1/2 text-start whitespace-nowrap">
                        One time university registration fee <br />
                        ( <small>Non-refundable</small> )
                      </p>
                      <p class="w-1/2 text-end">
                        {{ this.formFeeInfo.reg_fee }}
                      </p>
                    </div>
                    <div class="w-full flex flex-row text-start">
                      <p class="w-1/2 text-start">Examination fee</p>
                      <p class="w-1/2 text-end">
                        {{
                          (this.formFeeInfo.exam_fee =
                            this.formFeeInfo.feePreference == "Program fee"
                              ? this.exam_fee * 2
                              : this.exam_fee)
                        }}
                      </p>
                    </div>
                    <div class="w-full flex flex-row text-start">
                      <p class="w-1/2 text-start">Program fee</p>
                      <p class="w-1/2 text-end">
                        {{
                          (this.formFeeInfo.program_fee =
                            this.formFeeInfo.feePreference == "Program fee"
                              ? this.formFeeInfo.fee * 4
                              : this.formFeeInfo.feePreference == "Yearly fee"
                              ? this.formFeeInfo.fee * 2
                              : this.formFeeInfo.fee)
                        }}
                      </p>
                    </div>
                    <div class="w-full flex flex-row text-start">
                      <p class="w-1/2 text-start">Discount amount</p>
                      <p class="w-1/2 text-end">
                        {{ this.formFeeInfo.coupon_code }}
                      </p>
                    </div>
                    <div class="w-full flex flex-row text-start">
                      <p class="w-1/2 text-start font-semibold">
                        Payable amount
                      </p>
                      <p class="w-1/2 text-end">
                        {{
                          this.formFeeInfo.reg_fee +
                          this.formFeeInfo.exam_fee +
                          this.formFeeInfo.program_fee -
                          this.formFeeInfo.coupon_code
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full mt-10 flex flex-row justify-center gap-10">
                  <button
                    class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                  >
                    Pay Now
                  </button>
                  <button
                    class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                  >
                    Apply Loan
                  </button>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
               You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
             </div> -->
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(2)"
                  />
                  <button
                    type="submit"
                    class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>

            <div
              class="w-full xl:w-2/5 h-auto bg-white flex flex-row items-center justify-start rounded-xl md:shadow-xl shadow-slate-300/50 p-3 text-base"
            >
              <div class="flex flex-col gap-4">
                <div class="flex flex-row gap-4 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-user-circle"
                      class="text-xl pr-2"
                    />
                    Name:
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.fullName }}
                  </p>
                </div>
                <div class="flex flex-row gap-4 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-at"
                      class="text-xl pr-2"
                    />Email:
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.emailAddress }}
                  </p>
                </div>
                <div class="flex flex-row gap-4 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-mobile"
                      class="text-xl pr-2"
                    />
                    Phone:
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.CodePrimary }}
                    {{ this.formStudentInfo.mobilePrimary }}
                  </p>
                </div>
                <div
                  v-if="this.studentBasicInfo.selectedProgram"
                  class="flex flex-row gap-4 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-book"
                      class="text-xl pr-2"
                    />
                    Program:
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.studentBasicInfo.selectedProgram }}
                  </p>
                </div>
                <div
                  v-if="this.studentBasicInfo.selectedElective"
                  class="flex flex-row gap-4 items-start justify-start"
                >
                  <p
                    class="text-[#01215c] font-medium flex gap-2 justify-start items-start"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-bookmark"
                      class="text-xl pr-2"
                    />
                    <span>Elective:</span>
                  </p>
                  <p class="font-light text-[#01215c] text-justify">
                    {{ this.studentBasicInfo.selectedElective }}
                  </p>
                </div>
                <div
                  v-if="this.studentBasicInfo.selectedNationality"
                  class="flex flex-row gap-4 items-start"
                >
                  <p class="text-[#01215c] font-medium flex items-start">
                    <font-awesome-icon
                      icon="fa-solid fa-flag"
                      class="text-xl pr-2"
                    />
                    Nationality :
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.studentBasicInfo.selectedNationality }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex flex-row px-8 bg-pink-200">
         <div class="w-full 2xl:w-3/5 flex flex-row justify-between px-2">
           <font-awesome-icon icon="fa-solid fa-caret-left" class="text-7xl text-[#01215c]" />
           <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c]" />
         </div>
       </div> -->
        </div>
      </form>
      <!-- <img src="../../assets/background.png" alt="background" class="absolute inset-0"> -->
    </div>
  </div>
</template>

<script>
// import Navigation from '@/components/NavigationView.vue'
import Tracking from "@/components/TrackingView.vue";
// import LeftNavigation from '@/components/FormLeftNavView.vue'
import axios from "axios";
import $ from "jquery";
export default {
  name: "BasicInfoForm",
  data() {
    return {
      isStepOne: true,
      isStepTwo: false,
      isStepThree: false,
      isStepFour: false,
      isStepFive: false,
      isStepSix: false,

      exam_fee: 3000,
      fee_preference: "Program fee",
      find_nationality: "Indian",
      is_coupon_open: false,
      is_loanRequired: "No",
      is_already_done: false,
      studentBasicInfo: {},

      formStudentInfo: {
        fullName: "Sample Name",
        emailAddress: "johndoe@gmail.com",
        CodePrimary: "+91",
        mobilePrimary: "9898767676",
      },

      formFeeInfo: {
        fee: 55000,
        reg_fee: 2500,
        feePreference: "Program fee",
        coupon_code: 0,
        exam_fee: 0,
        program_fee: 0,
      },

      isPopup: false,
    };
  },
  props: ["id"],
  components: {
    // Navigation,
    Tracking,
    // LeftNavigation
  },

  async created() {
    await this.programCall();
    await this.genderCall();
    await this.nationalityCall();
    await this.ugDegreeCall();
    await this.countryCall();
    await this.highestEducation();

    if (
      localStorage.getItem("Tracking") &&
      localStorage.getItem("studentInfo")
    ) {
      let Tracking = JSON.parse(localStorage.getItem("Tracking"));
      let studentInfo = JSON.parse(localStorage.getItem("studentInfo"));
      this.studentBasicInfo =
        studentInfo.formsInfo.basicInfo.basicInfo_formdata;
      if (Tracking["basic"] == true) {
        this.is_already_done = true;
      }
    } else {
      let tracking = {
        basic: false,
        contact: false,
        eduaction: false,
        fee: false,
        document: false,
      };
      let studentId = {
        student_id:
          this.id == "" ? "f11a2523-2f3d-44d9-9979-f2642f8b4cfd" : this.id,
        formsInfo: {},
      };

      localStorage.setItem("Tracking", JSON.stringify(tracking));
      localStorage.setItem("studentInfo", JSON.stringify(studentId));
    }
  },
  methods: {
    disclaimer() {
      console.log("ese oin");
      $(".disclaimer_text").toggle("slow");
    },
    async highestEducation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/highest-qualification-degree-types`
        )
        .then((resp) => {
          this.highest_education_list = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async countryCall() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/demography/country`
        )
        .then((resp) => {
          this.country_list = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async ugDegreeCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/ug-degree-types`
        )
        .then((resp) => {
          this.ugDegree_val = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async nationalityCall() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/nationality`)
        .then((resp) => {
          this.nationality_val = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async genderCall() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/genders`)
        .then((resp) => {
          this.gender_val = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async selectElective(el) {
      let selectedProgram =
        el.target.selectedOptions[0].attributes.selected_val.value;
      this.selectedProgramVal = el.target.value;
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/electives?batch=6BDQW5X4ENO&program_id=${selectedProgram}`
        )
        .then((resp) => {
          this.electives = resp.data;
        });
    },

    async programCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/programs?batch=9QOYNV5N18U`
        )
        .then((resp) => {
          this.programs = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    feeinfo() {
      let FeeStatus = {
        fee_formdata: this.formFeeInfo,
        submitTime: new Date(),
      };

      let getStudentVal = JSON.parse(localStorage.getItem("studentInfo"));
      let Tracking = JSON.parse(localStorage.getItem("Tracking"));

      getStudentVal["formsInfo"]["FeeInfo"] = FeeStatus;

      localStorage.setItem("studentInfo", JSON.stringify(getStudentVal));

      Tracking["fee"] = true;

      localStorage.setItem("Tracking", JSON.stringify(Tracking));

      this.$router.push({ name: "KycInfoForm" });
    },
    selectStep(step) {
      if (step == 1) {
        this.isStepOne = true;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 2) {
        this.isStepOne = false;
        this.isStepTwo = true;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 3) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = true;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 4) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = true;
        this.isStepFive = false;
        this.isStepSix = false;
      } else if (step == 5) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = true;
        this.isStepSix = false;
      } else if (step == 6) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = true;
      }
    },
    // @click="selectFile('passport')"
  },
};
</script>

<style src="@/assets/css/ProgressTracking.css"></style>
