<template>
  <div class="w-full min-h-screen mx-auto relative flex flex-col">
    <div class="bg-gray-50 p-4 flex flex-row justify-between items-center">
      <img
        src="../../assets/galecta-logo-black-nobg.svg"
        alt=""
        class="h-6 md:h-9"
      />
      <div
        class="flex flex-row gap-3 items-center border border-[#01215c] rounded-full px-2 py-1 shadow text-xs md:text-base"
      >
        <font-awesome-icon
          icon="fa-solid fa-user-circle"
          class="text-base md:text-xl"
        />
        User name
        <font-awesome-icon icon="fa-solid fa-caret-down" class="text-sm" />
      </div>
    </div>
    <div
      class="w-full 2xl:max-w-7xl mx-auto min-h-[95vh]flex flex-col items-center 2xl:py-16"
    >
      <Tracking completedStage="4" currentPage="5" />
      <form @submit.prevent="educationformPost">
        <div
          class="flex flex-row justify-between items-center text-xl w-full px-2 font-bold"
        >
          <router-link
            :to="{ name: 'communicationForm' }"
            class="font-medium text-base"
          >
            <font-awesome-icon icon="fas fa-chevron-left" class="pr-3" />Contact
            Information
          </router-link>
          <p class="text-start text-lg font-medium text-teal-600">
            Academic Information
          </p>
          <router-link
            :to="{ name: 'DocumentUploadForm' }"
            class="font-medium text-base"
          >
            Document Upload
            <font-awesome-icon icon="fas fa-chevron-right" class="pr-3" />
          </router-link>
        </div>
        <div
          class="w-full flex flex-col items-center min-h-full bg-slate-50 rounded-xl"
        >
          <div class="hidden">
            <input
              type="text"
              name="first_name"
              id="fullName"
              v-model="this.formStudentInfo.fullName"
            />
            <input
              type="email"
              name="email"
              id="emailAddress"
              v-model="this.formStudentInfo.emailAddress"
            />
            <input
              type="tel"
              id="countryCodePrimary"
              name="countryCodePrimary"
              v-model="this.formStudentInfo.CodePrimary"
            />
            <input
              type="tel"
              name="mobile_numberPrimary"
              id="mobile_numberPrimary"
              v-model="this.formStudentInfo.mobilePrimary"
            />
          </div>

          <div
            class="w-full flex flex-col-reverse xl:flex-row rounded-xl overflow-hidden gap-2 md:gap-8 p-4 md:p-8 h-[85vh] xl:h-[65vh]"
          >
            <div
              v-show="this.isStepOne"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full h-full flex flex-col gap-5 md:gap-10 items-start md:items-center md:justify-center md:mb-24 mt-6"
              >
                <p class="text-start text-xl md:text-xl font-light">
                  10th / SSLC Details
                </p>
                <div class="w-full md:w-2/3 text-start flex flex-col gap-2">
                  <div class="w-full text-start">
                    <label for="SecondarySchool" class="w-full font-medium"
                      >School name <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      id="SecondarySchool"
                      minlength="10"
                      maxlength="30"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                      v-model="education_info.sslc_school_Name"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="SecondarySchoolBoard" class="w-full font-medium"
                      >Board <small class="text-red-500">*</small></label
                    >
                    <select
                      id="SecondarySchoolBoard"
                      name="SecondarySchoolBoard"
                      autocomplete="SecondarySchoolBoard"
                      v-model="education_info.sslc_board"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="">Select</option>
                      <option
                        v-for="tenthboard in this.tenth_board_val"
                        :key="tenthboard"
                        :value="tenthboard.name"
                      >
                        {{ tenthboard.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl text-xs md:text-sm">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-5xl md:text-7xl text-gray-300"
                    @click="selectStep(1)"
                  />
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-5xl md:text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(2)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepTwo"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">10th / SSLC Details</p>
                <div class="w-full md:w-2/3 text-start flex flex-col gap-2">
                  <div class="w-full text-start">
                    <label for="Secondaryyop" class="w-full font-medium"
                      >Year of Passing
                      <span class="text-xs text-slate-500">(Year only)</span>
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      placeholder="YYYY"
                      name="Secondaryyop"
                      minlength="4"
                      maxlength="4"
                      v-model="education_info.sslc_yop"
                      id="Secondaryyop"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="Secondaryscheme" class="w-full font-medium"
                      >Marking Scheme
                      <small class="text-red-500">*</small></label
                    >
                    <select
                      id="Secondaryscheme"
                      name="Secondaryscheme"
                      autocomplete="Secondaryscheme"
                      v-model="education_info.sslc_mark_scheme"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="Select">Select</option>
                      <option
                        v-for="secondaryScheme in this.scondary_marking_scheme"
                        :key="secondaryScheme"
                        :value="secondaryScheme[0]"
                      >
                        {{ secondaryScheme[1] }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full text-start">
                    <label for="SecondaryCgpa" class="w-full font-medium"
                      >Aggregate Percentage/CGPA Obtained
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      name="SecondaryCgpa"
                      id="SecondaryCgpa"
                      v-model="education_info.sslc_percentage"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(1)"
                  />
                  <div>
                    <font-awesome-icon
                      icon="fa-solid fa-caret-right"
                      class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                      @click="selectStep(3)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepThree"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">
                  12th / HSC / Diploma / ITI Details
                </p>
                <div class="w-full md:w-2/3 text-start flex flex-col gap-2">
                  <div class="w-full text-start">
                    <label for="pueSchoolName" class="w-full font-medium"
                      >School Name <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      id="pueSchoolName"
                      v-model="education_info.hsc_school_Name"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="pueSchoolBoard" class="w-full font-medium"
                      >Board <small class="text-red-500">*</small></label
                    >
                    <select
                      id="pueSchoolBoard"
                      name="pueSchoolBoard"
                      autocomplete="pueSchoolBoard"
                      v-model="education_info.hsc_board"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="Select">Select</option>
                      <option
                        v-for="twelth in this.twelth_board_val"
                        :key="twelth"
                        :value="twelth.name"
                      >
                        {{ twelth.name }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full text-start">
                    <label for="stream" class="w-full font-medium"
                      >Stream <small class="text-red-500">*</small></label
                    >
                    <select
                      id="stream"
                      name="stream"
                      autocomplete="stream"
                      v-model="education_info.hsc_stream"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="Select">Select</option>
                      <option
                        v-for="stream in this.stream_list"
                        :key="stream"
                        :value="stream[0]"
                      >
                        {{ stream[1] }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(2)"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFour"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">
                  12th / HSC / Diploma / ITI Details
                </p>
                <div class="w-full md:w-2/3 text-start flex flex-col gap-2">
                  <div class="w-full text-start">
                    <label for="pueYop" class="w-full font-medium"
                      >Year of Passing
                      <span class="text-xs text-slate-500">(Year only)</span>
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      placeholder="YYYY"
                      name="pueYop"
                      id="pueYop"
                      v-model="education_info.hsc_yop"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="pueScheme" class="w-full font-medium"
                      >Marking Scheme<small class="text-red-500"
                        >*</small
                      ></label
                    >
                    <select
                      id="pueScheme"
                      name="pueScheme"
                      autocomplete="pueScheme"
                      v-model="education_info.hsc_mark_scheme"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="Select">Select</option>
                      <option
                        v-for="scheme in this.preDegree_marking_scheme"
                        :key="scheme"
                        :value="scheme[0]"
                      >
                        {{ scheme[1] }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full text-start">
                    <label for="pueCgpa" class="w-full font-medium"
                      >Aggregate Percentage/CGPA Obtained
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      name="pueCgpa"
                      id="pueCgpa"
                      v-model="education_info.hsc_percentage"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(3)"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(5)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepFive"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">Graduation Details</p>
                <div class="w-full md:w-1/2 text-start">
                  <label for="ugResult" class="w-full font-medium"
                    >UG Result Status
                    <small class="text-red-500">*</small></label
                  >
                  <select
                    id="ugResult"
                    name="ugResult"
                    v-model="education_info.ug_result_status"
                    autocomplete="ugResult"
                    class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                  >
                    <option selected disabled value="Select">Select</option>
                    <option value="awaited">Awaited</option>
                    <option value="declared">Declared</option>
                  </select>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div class="w-full flex flex-row justify-between mt-4">
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(4)"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(6)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepSix"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">Graduation Details</p>
                <div class="w-full md:w-2/3 text-start flex flex-col gap-2">
                  <div class="w-full text-start">
                    <label for="collegeName" class="w-full font-medium"
                      >Name of the College
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      id="collegeName"
                      v-model="education_info.name_college"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="universityName" class="w-full font-medium"
                      >Name of the University
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      id="universityName"
                      v-model="education_info.name_university"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="ugDegree" class="w-full font-medium"
                      >Degree <small class="text-red-500">*</small></label
                    >
                    <select
                      id="ugDegree"
                      name="ugDegree"
                      autocomplete="ugDegree"
                      v-model="education_info.degree"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="Select">Select</option>
                      <option
                        v-for="ugDegree in this.ugDegree_val"
                        :key="ugDegree"
                        :value="ugDegree[0]"
                      >
                        {{ ugDegree[0] }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(5)"
                  />
                  <!-- <button type="submit" class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]">Save & Continue</button> -->
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(7)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepSeven"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">Graduation Details</p>
                <div class="w-full md:w-2/3 text-start flex flex-col gap-2">
                  <div class="w-full text-start">
                    <label for="ugYop" class="w-full font-medium"
                      >Year of Passing
                      <span class="text-xs text-slate-500">(Year only)</span>
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      placeholder="YYYY"
                      name="ugYop"
                      id="ugYop"
                      v-model="education_info.ug_yop"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="ugmarkScheme" class="w-full font-medium"
                      >Marking Scheme
                      <small class="text-red-500">*</small></label
                    >
                    <select
                      id="ugmarkScheme"
                      name="ugmarkScheme"
                      autocomplete="ugmarkScheme"
                      v-model="education_info.ug_mark_scheme"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="Select">Select</option>
                      <option
                        v-for="ugscheme in this.ugDegree_scheme_val"
                        :key="ugscheme"
                        :value="ugscheme[0]"
                      >
                        {{ ugscheme[1] }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full text-start">
                    <label for="ugCgpa" class="w-full font-medium"
                      >Aggregate Percentage/CGPA Obtained
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      name="ugCgpa"
                      id="ugCgpa"
                      v-model="education_info.ug_percentage"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(6)"
                  />
                  <!-- <button type="submit" class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]">Save & Continue</button> -->
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(8)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepEight"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">Work Experience</p>
                <div class="w-full md:w-2/3 text-start">
                  <div class="w-full text-start">
                    <label for="workExperience" class="w-full font-medium"
                      >Do you have work experience?
                      <small class="text-red-500">*</small></label
                    >
                    <select
                      id="workExperience"
                      name="work_experience"
                      autocomplete="workExperience"
                      v-model="education_info.experience_status"
                      class="text-xs mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(7)"
                  />
                  <!-- <button type="submit" class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]">Save & Continue</button> -->
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(9)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepNine"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">Work Experience</p>
                <div class="w-full md:w-2/3 text-start flex flex-col gap-2">
                  <div class="w-full text-start">
                    <label for="occupationType" class="w-full font-medium"
                      >Occupation <small class="text-red-500">*</small></label
                    >
                    <select
                      id="occupationType"
                      name="occupation_type"
                      autocomplete="occupationType"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    >
                      <option selected disabled value="">Select</option>
                      <option value="Part time">Part time</option>
                      <option value="Full time">Full time</option>
                    </select>
                  </div>
                  <div class="w-full text-start">
                    <label for="totalExperiece" class="w-full font-medium"
                      >Total experience
                      <small class="text-red-500">*</small></label
                    >
                    <div class="mt-1 w-full flex flex-row gap-3">
                      <div class="w-1/2 flex flex-row items-center">
                        <input
                          type="text"
                          id="totalExperieceYear"
                          name="totalExperiece_year"
                          value="0"
                          minlength="1"
                          maxlength="2"
                          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                          class="w-12 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                        />
                        <label for="totalExperieceYear" class="font-medium px-2"
                          >Years</label
                        >
                      </div>
                      <div class="w-1/2 flex flex-row items-center">
                        <input
                          type="text"
                          id="totalExperieceMonth"
                          name="totalExperiece_month"
                          value="0"
                          minlength="1"
                          maxlength="2"
                          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                          class="w-12 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                        />
                        <label
                          for="totalExperieceMonth"
                          class="font-medium px-2"
                          >Months</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(8)"
                  />
                  <!-- <button type="submit" class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]">Save & Continue</button> -->
                  <font-awesome-icon
                    icon="fa-solid fa-caret-right"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(10)"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="this.isStepTen"
              class="w-full xl:w-3/5 h-full flex flex-col items-center justify-center rounded-xl relative"
            >
              <div
                class="w-full flex flex-col gap-5 md:gap-10 items-center mb-24"
              >
                <p class="text-start text-xl font-light">Employer Detail</p>
                <div class="w-full md:w-2/3 text-start">
                  <div class="w-full text-start">
                    <label for="employerName" class="w-full font-medium"
                      >Employer Name
                      <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      name="employerName"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="designation" class="w-full font-medium"
                      >Designation <small class="text-red-500">*</small></label
                    >
                    <input
                      type="text"
                      name="designation"
                      class="mt-1 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                    />
                  </div>
                  <div class="w-full text-start">
                    <label for="duration" class="w-full font-medium"
                      >Duration <small class="text-red-500">*</small></label
                    >
                    <div class="mt-1 w-full flex flex-row gap-3">
                      <div class="w-1/2 flex flex-row items-center">
                        <input
                          type="text"
                          id="totalExperieceYear"
                          name="totalExperiece_year"
                          value="0"
                          minlength="1"
                          maxlength="2"
                          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                          class="w-12 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                        />
                        <label for="totalExperieceYear" class="font-medium px-2"
                          >Years</label
                        >
                      </div>
                      <div class="w-1/2 flex flex-row items-center">
                        <input
                          type="text"
                          id="totalExperieceMonth"
                          name="totalExperiece_month"
                          value="0"
                          minlength="1"
                          maxlength="2"
                          onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                          class="w-12 block w-full border border-sky-100 bg-white py-2 px-3 shadow-sm focus:border-sky-400 focus:outline-none focus:ring-sky-400"
                        />
                        <label
                          for="totalExperieceMonth"
                          class="font-medium px-2"
                          >Months</label
                        >
                      </div>
                    </div>
                  </div>
                  <!-- <div class="flex flex-row justify-center py-4">
                  <button class="h-8 text-xs font-semibold px-1 py-1 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]">Add More</button>  
                </div> -->
                </div>
              </div>
              <div class="w-full absolute bottom-0 px-6">
                <!-- <div class="bg-sky-100 flex flex-row justify-center items-center p-3 text-black font-light rounded-xl">
                 You can also use variant modifiers to target media queries like responsive breakpoints, dark mode, prefers-reduced-motion, and more.
               </div> -->
                <div
                  class="w-full flex flex-row justify-between items-center mt-4"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-caret-left"
                    class="text-7xl text-[#01215c] hover:text-[#fcb21f]"
                    @click="selectStep(9)"
                  />
                  <button
                    type="submit"
                    class="w-32 h-10 text-xs font-semibold px-3 py-2 text-white bg-[#01215c] rounded hover:bg-[#fcb21f]"
                  >
                    Save & Continue
                  </button>
                  <!-- <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c] hover:text-[#fcb21f]" @click="selectStep(10)"/> -->
                </div>
              </div>
            </div>

            <div
              class="w-full xl:w-2/5 h-auto bg-white flex flex-row items-center justify-center rounded-xl md:shadow-xl shadow-slate-300/50 p-3 text-sm"
            >
              <div class="flex flex-col gap-3">
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-user-circle"
                      class="text-xl pr-2"
                    />
                    Name
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.fullName }}
                  </p>
                </div>
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-at"
                      class="text-xl pr-2"
                    />Email
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.emailAddress }}
                  </p>
                </div>
                <div class="flex flex-row gap-3 items-center">
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-mobile"
                      class="text-xl pr-2"
                    />
                    Phone
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.CodePrimary }}
                    {{ this.formStudentInfo.mobilePrimary }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedProgram"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-book"
                      class="text-xl pr-2"
                    />
                    Program
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedProgram }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedElective"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-bookmark"
                      class="text-xl pr-2"
                    />
                    Elective
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedElective }}
                  </p>
                </div>
                <div
                  v-if="this.formStudentInfo.selectedNationality"
                  class="flex flex-row gap-3 items-center"
                >
                  <p class="text-[#01215c] font-medium">
                    <font-awesome-icon
                      icon="fa-solid fa-flag"
                      class="text-xl pr-2"
                    />
                    Nationality
                  </p>
                  <p class="font-light text-[#01215c]">
                    {{ this.formStudentInfo.selectedNationality }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="flex flex-row px-8 bg-pink-200">
           <div class="w-full 2xl:w-3/5 flex flex-row justify-between px-2">
             <font-awesome-icon icon="fa-solid fa-caret-left" class="text-7xl text-[#01215c]" />
             <font-awesome-icon icon="fa-solid fa-caret-right" class="text-7xl text-[#01215c]" />
           </div>
         </div> -->
        </div>
      </form>
      <!-- <img src="../../assets/background.png" alt="background" class="absolute inset-0"> -->
    </div>
  </div>
</template>

<script>
// import Navigation from '@/components/NavigationView.vue'
import Tracking from "@/components/TrackingView.vue";
// import LeftNavigation from '@/components/FormLeftNavView.vue'
import axios from "axios";
export default {
  name: "BasicInfoForm",
  data() {
    return {
      ug_result: "Selesct",
      is_already_done: false,
      is_experience: "No",
      tenth_board_val: [],
      twelth_board_val: [],
      ugDegree_val: [],
      ugDegree_scheme_val: [],
      stream_list: [],
      preDegree_marking_scheme: [],
      scondary_marking_scheme: [],
      experience_list: 1,
      degree_status: "select",

      isStepOne: true,
      isStepTwo: false,
      isStepThree: false,
      isStepFour: false,
      isStepFive: false,
      isStepSix: false,
      isStepSeven: false,
      isStepEight: false,
      isStepNine: false,
      isStepTen: false,

      formStudentInfo: {
        fullName: "Sample Name",
        emailAddress: "johndoe@gmail.com",
        CodePrimary: "+91",
        mobilePrimary: "9898767676",
      },
      education_info: {
        sslc_school_Name: "",
        sslc_board: "",
        sslc_yop: "",
        sslc_mark_scheme: "",
        sslc_percentage: "",
        hsc_school_Name: "",
        hsc_board: "",
        hsc_stream: "",
        hsc_yop: "",
        hsc_mark_scheme: "",
        hsc_percentage: "",
        ug_result_status: "",
        name_college: "",
        name_university: "",
        degree: "",
        ug_yop: "",
        ug_mark_scheme: "",
        ug_percentage: "",
        experience_status: "",
        experience: [
          {
            occupation: "",
            total_experience_yr: "",
            total_experience_mn: "",
          },
          {
            occupation: "",
            total_experience_yr: "",
            total_experience_mn: "",
          },
          {
            occupation: "",
            total_experience_yr: "",
            total_experience_mn: "",
          },
        ],
      },
    };
  },
  components: {
    // Navigation,
    Tracking,
    // LeftNavigation
  },

  async created() {
    let Tracking = JSON.parse(localStorage.getItem("Tracking"));
    if (Tracking["eduaction"] == true) {
      this.is_already_done = true;
    }

    this.tenthGradeBoardCall();
    this.twelthGradeBoardCall();
    this.ugDegreeCall();
    this.ugDegreeSchemeCall();
    this.streamCall();
    this.preDegreeMarkingSchemeCall();
    this.scondaryMarkingSchemeCall();
  },

  methods: {
    async scondaryMarkingSchemeCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/tenth-grade-marking-scheme-types`
        )
        .then((resp) => {
          this.scondary_marking_scheme = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async preDegreeMarkingSchemeCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/pre-degree-marking-scheme-types`
        )
        .then((resp) => {
          this.preDegree_marking_scheme = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async streamCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/pre-degeree-stream`
        )
        .then((resp) => {
          this.stream_list = resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async ugDegreeSchemeCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/ug-marking-scheme-types`
        )
        .then((resp) => {
          this.ugDegree_scheme_val = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async ugDegreeCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/YLL83YIMPDQ/core-data/ug-degree-types`
        )
        .then((resp) => {
          this.ugDegree_val = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async tenthGradeBoardCall() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/master-data/tenth-grade-board`)
        .then((resp) => {
          this.tenth_board_val = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async twelthGradeBoardCall() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/master-data/twelfth-grade-board`
        )
        .then((resp) => {
          this.twelth_board_val = resp.data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    educationformPost() {
      let educationstatus = {
        education_formdata: this.education_info,
        submitTime: new Date(),
      };

      let getStudentVal = JSON.parse(localStorage.getItem("studentInfo"));
      let Tracking = JSON.parse(localStorage.getItem("Tracking"));

      // getStudentVal['formsInfo'].push(educationstatus)
      getStudentVal["formsInfo"]["educationInfo"] = educationstatus;

      localStorage.setItem("studentInfo", JSON.stringify(getStudentVal));

      Tracking["eduaction"] = true;

      localStorage.setItem("Tracking", JSON.stringify(Tracking));

      this.$router.push({ name: "DocumentUploadForm" });
    },
    selectStep(step) {
      if (step == 1) {
        this.isStepOne = true;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.isStepTen = false;
      } else if (step == 2) {
        this.isStepOne = false;
        this.isStepTwo = true;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.isStepTen = false;
      } else if (step == 3) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = true;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.isStepTen = false;
      } else if (step == 4) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = true;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.isStepTen = false;
      } else if (step == 5) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = true;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.isStepTen = false;
      } else if (step == 6) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = true;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.isStepTen = false;
      } else if (step == 7) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = true;
        this.isStepEight = false;
        this.isStepNine = false;
        this.isStepTen = false;
      } else if (step == 8) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = true;
        this.isStepNine = false;
        this.isStepTen = false;
      } else if (step == 9) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = true;
        this.isStepTen = false;
      } else if (step == 10) {
        this.isStepOne = false;
        this.isStepTwo = false;
        this.isStepThree = false;
        this.isStepFour = false;
        this.isStepFive = false;
        this.isStepSix = false;
        this.isStepSeven = false;
        this.isStepEight = false;
        this.isStepNine = false;
        this.isStepTen = true;
      }
    },
    // @click="selectFile('passport')"
  },
};
</script>

<style src="@/assets/css/ProgressTracking.css"></style>
